window.save_user = function (tabId, form_data, type) {

  $(".loader").show();
  $(".invalid-feedback").remove();

    $.post('/user/add/validate', form_data).done(function (data) {

        if (data.isErr == true) {

            display_validate_errors(data.errors);
            $(".loader").hide();

        } else {

            $.post('/user/add', form_data).done(function (data) {

                if (data.isErr == false) {
                    if (type == 'updatebtnClicked') {

                        $(".loader").hide();
                        dialog('update-user', $(this).attr('href'));

                    } else {

                        if (type == 'updateTab') {

                            active_tab($("#"+tabId).attr('href'));
                            var step=$("#"+tabId).parent('li').data("step");
                            $("#tab_type").val(step);
                            $(".loader").hide();
                        } else {

                            $('input[name="user_id"]').val(data.user_id);
                            window.location.href="/user";
                        }

                    }

                } else {

                    alert(data.err);
                    $(".loader").hide();
                }
            });
        }
  });
}
