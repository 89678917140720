$('.partner_tab a').on('click', function (e) {
     $(".loader").show();
    var form_id = $(".tab-content").parents('form').attr('id');
    var form_data = $(".tab-content").parents('form').serialize();
    var currentTab = $('.partner_tab').find('.nav-link.active').attr('id');
    var nxtTabUrl = $(this).attr('href');

    if (!form_id) {
        window.location = nxtTabUrl;
         return false;
    }

    $.post('/partner/confirm/saveChange/' + currentTab + '/' + $(this).attr('id'), form_data).done(function (data) {
        if (data.openDialogue == 'yes') {

            $(".loader").hide();
            $('#confirmModal').html(data.html);
            $('#confirmModal').modal();

        } else {
            window.location = nxtTabUrl;
        }
          return false;


    });
    return false;

});

$("body").on("click", ".update_partner", function (e) {
    var form_id = $(".tab-content").parents('form').attr('id');
    var form_data = $(".tab-content").parents('form').serialize();
    form_data += '&redirectUrl=' + $('#' + $(this).attr('data-tab')).attr('href');
    var redirectUrl = $('#' + $(this).attr('data-tab')).attr('href');
    $("#" + form_id).append('<input type="hidden" name="redirectUrl" value="' + redirectUrl + '">');
    $("#" + form_id).submit();

});


$("body").on("click", ".update_partner_no_btn", function (e) {
     window.location = $('#' + $(this).attr('data-tab')).attr('href');
});
