

window.datepickerInit = function () {
    $('.datepicker').daterangepicker({
            singleDatePicker: true,
            showDropdowns: true,
            minYear: 1950,
            maxYear: 2050,
            locale: {
              format: 'DD/MM/YYYY'
            },
            autoUpdateInput: false
    });
    $('.datepicker').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('DD/MM/YYYY'));
    });

    $('.datepicker2').daterangepicker({
            singleDatePicker: true,
            showDropdowns: true,
            minYear: 2020,
            maxYear: 2050,
            locale: {
              format: 'DD/MM/YYYY'
            },
            autoUpdateInput: false
    });
    $('.datepicker2').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('DD/MM/YYYY'));

    });
    $('.datepicker2').on('show.daterangepicker', function(ev, picker) {
        if($(this).attr('id')=='end_date'){
            setminDateInCalender($('#start_date').val(),'end_date');

        }

        if ($(this).hasClass('end_date')) {
            setminDateInCalender($(this).parents('.date-row').find('.start_date').val(),$(this).attr('id'));

        }

    });

    //

}
window.setminDateInCalender = function (mindate1,fieldid) {
    $('#'+fieldid).daterangepicker({
        minDate:mindate1,
        singleDatePicker: true,
        locale: {format: 'DD/MM/YYYY'},
    });
}
window.setmaxDateInCalender = function (maxdate1,fieldid) {
    $('#'+fieldid).daterangepicker({
        minDate:maxdate1,
        singleDatePicker: true,
        locale: {format: 'DD/MM/YYYY'},
    });
}
