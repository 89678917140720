window.validate_user = function (tabId, form_data, type) {

  $(".loader").show();
  $(".invalid-feedback").remove();

    $.post('/user/add/validate', form_data).done(function (data) {
        var step='';

        if (data.isErr == true) {

            display_validate_errors(data.errors);
            $(".loader").hide();

        } else {

            var next_tab ='';
            if (type == 'tabClicked') {

                if ($('.update_user').length > 0) {

                    var userid=$('input[name=user_id]').val();
                    var divdata = $('.tab-pane.active :input').serialize();

                    $.post('/user/confirmUserUpdate/' + userid + '/' + tabId, divdata).done(function (data) {

                        if (data.openDialogue == 'yes') {

                            $(".loader").hide();
                            $('#confirmModal').html(data.html);
                            $('#confirmModal').modal();

                        } else {

                            next_tab=$("#"+tabId);
                            step=$("#"+tabId).parent('li').data("step");
                            active_tab(next_tab.attr('href'));
                            $("#tab_type").val(step);
                            $(".loader").hide();

                        }
                    });
                } else {

                    next_tab=$("#"+tabId);
                    step = $("#" + tabId).parent('li').data("step");

                }
            }
            if (type == 'btnClicked') {

                next_tab=$("#"+tabId+"-link").parent('li').next('li').find('a');
                step = $("#" + tabId + "-link").parent('li').next('li').data("step");

            }
            if (type == "updateBtnClicked") {

                $(".loader").hide();
                save_user(tabId, form_data, 'updatebtnClicked');

            }
            if (next_tab.length > 0) {

                active_tab(next_tab.attr('href'));
                $("#tab_type").val(step);
                $(".loader").hide();

            }
        }
    });
}
