$.ajaxSetup({headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}});

$("body").on("keydown", ".numbersOnly", function(e){
  // Allow: backspace, delete, tab, escape, enter and
  //if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
  if ($.inArray(e.keyCode, [46, 8, 9, 27, 13]) !== -1 ||
    // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
    ((e.keyCode == 65 || e.keyCode == 86 || e.keyCode == 67) && (e.ctrlKey === true || e.metaKey === true)) ||
    // Allow: home, end, left, right, down, up
    (e.keyCode >= 35 && e.keyCode <= 40)) {
    // let it happen, don't do anything
    return;
  }
  // Ensure that it is a number and stop the keypress
  if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
    e.preventDefault();
  }
});
datepickerInit();

$("body").on("click", ".save_user", function(e){
  var form_data=$(this).parents('form').serialize();
  var tabId=$(this).parents('.tab-pane').attr('id');
	save_user(tabId,form_data,'btnClicked');
	e.preventDefault();
});

$("body").on("click", ".update_user", function(e){
  var form_data=$('#add-user-form').serialize();
  var tabId=$(this).parents('.tab-pane').attr('id');
  validate_user(tabId,form_data,'updateBtnClicked');
	e.preventDefault();
});
$("body").on("click", ".update_user1", function(e){
  var form_data=$('#add-user-form').serialize();
  var tabId=$(this).attr('data-tab');
  save_user(tabId,form_data,'updateTab');
});

$("body").on("click", ".closeUpdateDialogue", function(e){
  var form_data=$('#add-user-form').serialize();
  var tabId=$(this).attr('data-tab');
  active_tab($("#"+tabId).attr('href'));
  var step=$("#"+tabId).parent('li').data("step");
  $("#tab_type").val(step);
  $(".loader").hide();
  //save_user(tabId,form_data,'updateTab');
});

$("body").on("click", ".next_user_step", function(e){
  var form_data=$(this).parents('form').serialize();
  var tabId=$(this).parents('.tab-pane').attr('id');
  validate_user(tabId,form_data,'btnClicked');
  e.preventDefault();
});

$("body").on("click", ".back_user_step", function(e){
  var tabId=$(this).parents('.tab-pane').attr('id');
  var prev_tab=$("#"+tabId+"-link").parent('li').prev('li').find('a');
  active_tab(prev_tab.attr('href'));
  $("#tab_type").val($("#"+tabId+"-link").parent('li').prev('li').data("step"));
  e.preventDefault();
});

$('.tab-click').on('click', function (e) {

  e.preventDefault();
  if(!$(this).hasClass('active')){
    $(".tab-click").removeClass('clicked');
    $(this).addClass('clicked');
    var form_data=$("#add-user-form").serialize();
    var tabId=$(this).attr('id');
    validate_user(tabId,form_data,'tabClicked');
    //dialog('change-user-tab','');
  }
})
$('.confirm-modal').on('click', function (e) {
    e.preventDefault();
    var type = $(this).attr('data-type')
    if (!type)
        type = 'delete-confirmation';
    dialog(type,$(this).attr('href'));
})

$("body").on("change", ".profile_image", function(e){
  var filename = $(this).val().replace(/C:\\fakepath\\/i, '')
  var formData = new FormData();
  var oFReader = new FileReader();
  $("#profile_image_label").text(filename);
  oFReader.readAsDataURL(document.getElementById("profile_image").files[0]);
  formData.append('file', document.getElementById("profile_image").files[0]);
  formData.append("removeImage", $("#image").val());

  $.ajax({
    url: "/user/image/upload",
    type: "POST",
    data: formData,
    processData: false,
    contentType: false,
    success: function(imageName){
      $("#image").val(imageName);
    }
  });
  e.preventDefault();
});

$("body").on('change', '.select_other', function (e) {
    if ($(this).val() == 'other') {
        $(this).next('input').removeClass('hide');
        $(this).prev('input').val(0);
    } else {
        $(this).next('input').addClass('hide').val("");
        $(this).prev('input').val($(this).val());
    }

});

$("body").on('change', '.select_title', function (e) {
    if ($(this).val() == 'other') {
        $(this).next('input').removeClass('hide').val("");
    } else {
        $(this).next('input').addClass('hide').val($(this).val());

    }

});



if($("#isUserEdit").val()=='true'){
  $(".tab-click").removeClass('disabled');
}

