$("body").on("click", ".save-jobPlan-btn", function (e) {

    $(".loader").show();
    $(".invalid-feedback").remove();
    $('input').removeClass('is-invalid');
    var formid = $(this).parents('form').attr('id');
    var form_data = $('#'+formid).serialize();
    var saveurl = $('#'+formid).attr('action');
    $.post(saveurl, form_data).done(function (data) {
        $(".loader").hide();
        if (data.isErr == true) {
            $.each(data.errors, function (key, value) {
                $('#'+formid).find('input[name='+key+']').addClass('is-invalid')
               $('#'+formid).find('input[name='+key+']').parent().append('<span class="error invalid-feedback">' + value + '</span>');
            });
        } else {
            var successHtml = '<div class="alert alert-success mt-2">' + data.message;
            successHtml += '</div>';
            $('#' + formid).append(successHtml);
        }


    });

});
