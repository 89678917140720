window.display_validate_errors = function (errors) {

    $('.invalid-feedback').remove();

    $('input').removeClass('is-invalid');

    var c=0;
    var autocomplete_vad = false;

    $.each(errors, function (key, value) {

        var key_arr=key.split('.');
        var field_id='';

        field_id=key_arr[0];
        if (key_arr.length > 1) {

            field_id = field_id + '_' + key_arr[1];

        }

        if (field_id != '') {

            var parent_elment = $('#' + field_id).parent();

            if ($('#' + field_id).hasClass('datepicker')) {

                parent_elment = parent_elment.parent();

            }

            $('#' + field_id).addClass('is-invalid');

            parent_elment.append('<span class="error invalid-feedback">' + value + '</span>');

            if (c == 0) {

                $('html, body').animate({

                    scrollTop: $('#' + field_id).offset().top - 100

                }, 1000, function () { $('#' + field_id).focus(); });

            }
            c++;

        }

        $('.invalid-feedback').show();
    });
}
