

window.dialog = function(type,href){
    $.post('/dialogue',{type:type,href:href}).done(function( data ) {
        $('#confirmModal').html(data.html);
        $('#confirmModal').modal();
    });
}


window.active_tab=function (target){
    var userid = $('input[name=user_id]').val();
    if (userid) {
        $.get('/user/getUpdateForm/' + $.trim(target.replace('#','')) + '/' + userid).done(function (data) {

            $(target).html(data.html);
            datepickerInit();
            $('.tab-click').removeClass('active');
            $('[href="' + target + '"]').addClass('active').removeClass('disabled');
            $('.tab-pane').removeClass('active').removeClass('show');
            $(target).addClass('active').addClass('show');
            $('.tab-content').scrollTop(0);
        });
    } else {
        $('.tab-click').removeClass('active');
        $('[href="' + target + '"]').addClass('active').removeClass('disabled');
        $('.tab-pane').removeClass('active').removeClass('show');
        $(target).addClass('active').addClass('show');
        $('.tab-content').scrollTop(0);
    }



}

